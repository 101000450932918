/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  // CUSTOM JS

  // full slider
  var swiper = new Swiper('.jsFullSlider', {
    pagination: '.swiper-pagination',
    paginationClickable: true,
    loop: true,
    slidesPerView: 1
    ,autoplay: 5000
  });
  // END full slider


  // Nav mobile
  $('.jsNavBt').click( function () {
    $('.jsHeader').toggleClass('show_menu');
    $('.jsNavShow').collapse('toggle');
  });
  // END Nav mobile

  // filter
  $('body').on( 'click', '.jsListFilter', function (e) {
    e.preventDefault();
    $('.gallery-mode').fadeOut(function () {
      $('.list-mode').fadeIn();
    });
  });
  $('body').on( 'click', '.jsPortraitFilter', function (e) {
    e.preventDefault();

    $('.list-mode').fadeOut(function () {
    $('.gallery-mode').fadeIn();
    });

  });
  // END filter


  // gallery

  // $('.jsGallery').magnificPopup({
  //   delegate: 'a',
  //   type: 'image',
  //   image: {
  //     markup: '<div class="mfp-figure">'+
  //     '<div class="wrap_img"><div class="mfp-img"></div></div>'+
  //     '</div>',
  //     verticalFit: true,
  //   },
  //   gallery:{
  //     enabled: true,
  //   }
  // });

  // END gallery


  window.lazySizesConfig = window.lazySizesConfig || {};
  window.lazySizesConfig.expand = 10; //default 360-500
  lazySizesConfig.expFactor = 1.5; //default: 1.7

  // CUSTOM JS END




})(jQuery); // Fully reference jQuery after this point.
